// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query LoginForm {
    loginForm {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export default QUERY;
